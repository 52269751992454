import React, { useMemo } from 'react'
import dynamic from 'next/dynamic'
import { useWindowSize } from '@/hooks/useWindowSize'
import type { FacetsType } from '@/types/ElasticSearch/SearchAPIResponse'
import { SortOptions } from './SortOptions'
import type { onFilterChangeHandler, onSortChangeHandler } from './types'

const Text = dynamic(import('@csc/dls/Text'))
const IconRenderer = dynamic(import('@csc/dls/IconRenderer'))
const FilterIcon = dynamic(import('@csc/dls/Icons/Filter'))

const Filter = dynamic(import('@csc/dls/Filter'))
const Modal = dynamic(import('@csc/dls/Modal'))
const AjaxSpinner = dynamic(import('../Generic/AjaxSpinner'))

interface MobileFilterFacetsProps {
  facets: FacetsType[],
  sortValue: string,
  displayCount?: boolean
  open: boolean
  onClose: () => void
  isLoading: boolean,
  onFilterSelected: onFilterChangeHandler,
  onSortChange: onSortChangeHandler
  onClearFilters: () => void
}

const MobileFilterFacets: React.FC<MobileFilterFacetsProps> = ({
  facets,
  open,
  onClose,
  sortValue,
  isLoading,
  displayCount = true,
  onFilterSelected,
  onSortChange,
  onClearFilters,
}) => {
  const { width } = useWindowSize()
  const isMobile = useMemo(() => width < 768, [width])
  const facetsWithoutReadyToShip = useMemo<FacetsType[]>(() => (
    [...facets].filter((f) => f.machineName !== 'readyToShip')
  ), [facets])

  return (open && isMobile) ? (
    <div className="vertical-filter block md:hidden">
      <Modal
        open={open}
        onClose={onClose}
        type="full"
        closeable
        footerButtons
        confirmButtonLabel="Apply"
        cancelButtonLabel="Clear"
        confirmButtonProps={{
          type: 'primary',
          label: 'Apply',
          id: 'Apply',
          onClick: onClose,
        }}
        cancelButtonProps={{
          type: 'secondary',
          label: 'Clear',
          id: 'Clear',
          onClick: () => {
            onClearFilters()
            onClose()
          },
        }}
      >
        {open && (
          <>
            <button
              type="button"
              onClick={onClose}
              className="cursor-pointer flex items-center w-fit sticky top-0 z-10 bg-white py-4"
            >
              <IconRenderer IconComponent={FilterIcon} className="mr-2" />
              <Text>Close Filter</Text>
            </button>
            <Filter
              name="filter"
              filterLabel="Filter By"
              label="Filter By"
              onFilterChange={onFilterSelected}
              sortLabel="Sort by"
              sortOptions={SortOptions}
              onSortChange={onSortChange}
              data-testid="mobile-facet"
              id="mobile-facet"
              facets={facetsWithoutReadyToShip}
              sortValue={sortValue}
              displayCount={displayCount}
            />
            <AjaxSpinner visible={isLoading} />
          </>
        )}
      </Modal>
    </div>
  ) : <></>
}

export default MobileFilterFacets
