import { useCallback, useEffect, useState } from 'react'

export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<{ width: number, height: number }>({
    width: 0, height: 0,
  })
  const handleResize = useCallback(() => {
    // Set window width/height to state
    if (typeof window !== 'object') {
      return
    }
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }, [])
  useEffect(() => {
    // only execute all the code below in client side

    if (typeof window !== 'undefined') {
      // Handler to call on window resize

      // Add event listener
      window.addEventListener('resize', handleResize)

      // Call handler right away so state gets updated with initial window size
      handleResize()

      // Remove event listener on cleanup
      return () => window.removeEventListener('resize', handleResize)
    }
    return () => {}
  }, [handleResize]) // Empty array ensures that effect is only run on mount
  return windowSize
}

export default useWindowSize
